import React,{useEffect} from 'react'
import '../Component/webinar.css'
import webinar from '../Images/webinar.png'
import webinarimg1 from '../Images/webinarimg1.jpeg'
import webinarimg2 from '../Images/webinarimg2.jpeg'
import webinarimg3 from '../Images/webinarimage3.png'

export default function UpcomingEvents() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <div class="upcomingcontainer">
    <div class="upcomingrow1">
        
    </div>
    <div class="upcomingrow2">
  
<div className="instruction" >
               <center><h1 style={{color:'navy',fontSize:'2rem',fontWeight:'700'}}>Keep an eye out for our upcoming events – coming soon!</h1></center> 
            </div>
    </div>
  </div>
  
  )
}
