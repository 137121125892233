import React, { useEffect, useRef } from 'react';
import '../PrivacyRegulations/hippa.css'
import gdprimgg from '../../Images/hipaaneed-removebg-preview.png'
import whatisgdpr from '../../Images/hipaaneed2.jpg'
import needgdpr from '../../Images/needgdpr-removebg-preview.png'
import ContactForm from '../../Component/ContactForm';
import hippaimg from '../../Images/HIPPA.png'
export default function Hippa() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const animationRef = useRef(null);
  useEffect(() => {
    const currentRef = animationRef.current;
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animationRef.current.classList.add('start-animation');
        } else {
          animationRef.current.classList.remove('start-animation');
        }
      });
    };
    const options = {
        threshold: 0.20,
      };
    const observer = new IntersectionObserver(callback, options);

    if (animationRef.current) {
      observer.observe(animationRef.current);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [animationRef]);
  return (
    <div className='hippacontainer'>
<div class="hippafirstrow">
        <div class="hippafirstrowcol1">
        <h1><center className='hippamaintitle'>HIPAA- Protecting Personal Health Information</center></h1>
        <p className='phsubtitle1'>The Health Insurance Portability and Accountability Act of 1996, or HIPAA, is a US statute that establishes security and privacy guidelines for safeguarding individuals' private health information. When it comes to healthcare clearinghouses, health plans, and providers who send medical data electronically, the law is applicable.</p>
        </div>
        <div class="hippafirstrowcol2"><img src={hippaimg} alt="" className='hippaimg'/>
        </div>
  </div>
                    <div class="hippasecondrow">
                            <div class="hippasecondrowcol2">
                            <h1><center className='hippamaintitle2'style={{marginTop:'-5vh'}}>What Is HIPAA?</center></h1><br />
                            <p  className='hippasubtitle2'><ol>
    <li>
      <strong>HIPAA:</strong><br></br>  HIPAA, the Health Insurance Portability and Accountability Act, serves two main purposes. It provides continuous health insurance coverage for workers facing job changes and safeguards sensitive patient data, setting standards for data protection in healthcare.
    </li>

    <li>
      <strong>Transformational Law:</strong><br></br> Enacted in 1996, HIPAA transformed healthcare practices and the handling of patient information, introducing significant changes in data protection and portability. 
    </li>

    <li>
      <strong>Compliance Rules:</strong><br></br> HIPAA defines stringent rules for healthcare organizations (covered entities) handling Protected Health Information (PHI). It ensures secure storage and transmission of PHI data.
    </li>

    <li>
      <strong>Accessibility and Portability:</strong><br></br> The act ensures individual health-care plans are accessible, portable, and renewable, enhancing the flexibility and rights of healthcare plan holders.
    </li>

    <li>
      <strong>Updated Information:</strong><br></br> For the latest insights into HIPAA, organizations can refer to updated resources like the HIPAA Explained article, providing comprehensive information on the act. 
    </li>
  </ol></p>
                            </div>
                            <div class="hippasecondrowcol1"><img src={gdprimgg} alt="" className='hippaimg2'/>
                            </div>
                      </div>
                                          <div className="hippathirdrow">
                                            <div className="hippathirdrowcol1"> <h3 >  <center className='hippamaintitle'style={{marginTop:'-10vh'}}>Why Is HIPAA Compliance Required for Your Healthcare Organization?</center>
                                            </h3>
                                                <p style={{color:'white'}}>Healthcare organizations are mandated to strictly adhere to HIPAA regulations to ensure the preservation of patient privacy and instill confidence in their patient community. Non-compliance with these regulations can result in substantial fines and penalties, posing a significant risk to the organization's reputation. As stipulated by HIPAA, healthcare businesses must implement a comprehensive framework of administrative, physical, and technical safeguards to protect Protected Health Information (PHI). These measures encompass the establishment of access restrictions, the undertaking of rigorous risk assessments, and the provision of ongoing education to personnel on privacy and security procedures. Upholding these professional standards is not only a legal obligation but also paramount in maintaining the trust and well-being of patients.
                                                </p></div>
                                            <div className="hippathirdrowcol2">
                                          <img src={whatisgdpr} alt="" className='whatisgdpr'/>
                                            </div>
                                          </div>
      <div className="hippafourthrow">
        <div className="hippafourthrowcol1">
<img src={needgdpr} alt="" className='hippaneed'/>
        </div>
        <div className="hippafourthrowcol2">
        <h3 >  <center style={{ paddingTop: '50px', fontSize: '30px',marginTop:'-10vh' }}>What Impact Does HIPAA Have on Your Healthcare Organization and You?</center>
        </h3>
      <p style={{color:'black',paddingTop:'10px'}}><ol>
    <li style={{color:'black',paddingTop:'10px'}}>
        <strong>Privacy Rule:</strong><br></br>        This regulation grants people specific rights over their health information and establishes guidelines for safeguarding their PHI.
    </li>

    <li style={{color:'black',paddingTop:'10px'}}>
        <strong>Security Regulation:</strong>
      <br></br>  Under this regulation, PHI must be protected by administrative, technical, and physical safeguards in healthcare organizations.
    </li>

    <li style={{color:'black',paddingTop:'10px'}}>
        <strong>Breach Notification Rule:</strong>
       <br></br> Under this rule, healthcare organizations must report any unsecured PHI breaches to the Department of Health and Human Services as well as to individuals.
    </li>

    <li style={{color:'black',paddingTop:'10px'}}>
        <strong>Omnibus Regulation:</strong>
       <br></br> This regulation broadens the definition of "business associate" to encompass firms that handle PHI on behalf of healthcare organizations and includes requirements that improve PHI security and privacy protections.
    </li>
</ol>

 </p>
        </div>
      </div>
      <div className="hippafifthrow">
        <div className="hippafifthrowcol">
        <div class="gdpranimation2" ref={animationRef}>
<h1 ><center className='maintitle4'>Our Methodology</center></h1>
   <div class="hippabox hippabox1"><center> <h3 className='gdpranimationText'>Conduct In-depth Assessment </h3> </center> <p className='phsubtitle3'>The degree of privacy compliance in your company will be ascertained by a thorough evaluation carried out by our team of accredited privacy experts. We will point out weaknesses and offer suggestions to improve your data protection and privacy strategy.
</p></div>  
      <div class="gdprhippaslide-tr"><center> <h3 className='gdpranimationText'>Develop Customized Strategy</h3> </center> <p className='phsubtitle3'>We design a privacy compliance approach based on your particular needs because we recognize that every organization is different. Our staff will collaborate with you to pinpoint problem areas and create a thorough compliance plan.</p></div>
      <div class="gdprhippaslide-right"><center><h3 className='gdpranimationText'>Offer Training Programs</h3> </center> <p className='phsubtitle3'> We give training courses that educate staff members about HIPAA compliance regulations. Our customized programs assist in guaranteeing that your personnel have the skills necessary to maintain compliance.</p></div>
      <div class="gdprhippaslide-left"><center><h3 className='gdpranimationText'>Monitor Compliance </h3> </center> <p className='phsubtitle3'>We offer ongoing oversight of your HIPAA compliance strategy to guarantee that all regulations are being followed. Any non-compliance will be pointed up by our team, along with suggestions for correction. Frequent monitoring supports HIPAA compliance by lowering the possibility of expensive data breaches.</p></div>
      <div class="gdprhippaslide-tl"><center><h3 className='gdpranimationText'>Provide Implementation Support </h3> </center> <p className='phsubtitle3'>Our team of professionals offers practical assistance and direction during the whole implementation process. We assist you in streamlining your operations and guaranteeing the successful implementation of your HIPAA compliance plan.
</p></div>
   </div>
        </div>
      </div>
      <div className="contactform">
        <ContactForm/>
      </div>
    </div>
  );
}