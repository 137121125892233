

// import React, { useState } from 'react';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import '../Component/Contactus.css';
// import emailjs from 'emailjs-com';



// export default function ContactForm() {
//   const sendEmail = (e) => {
//     e.preventDefault(); // Prevent the default form submission

//     const emailConfig = {
//       service_id: 'service_dxq10it',
//       template_id: 'template_1h4j137',
//       user_id: 'YtuxcGwsHApB3Y5iz',
//     };

//     emailjs
//       .sendForm(emailConfig.service_id, emailConfig.template_id, e.target, emailConfig.user_id)
//       .then(
//         (response) => {
//           console.log('Email sent successfully:', response);
//         },
//         (error) => {
//           console.error('Error sending email:', error);
//         }
//       );
//   };

//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     services: '',
//     message: '',
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleLinkedInClick = () => {
//     window.location.href = linkedInUrl;
//   };
// const linkedInUrl = 'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A10864106&keywords=insecsys&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=62bfc8c1-a6fc-4cf4-bd50-2134362db6d9&sid=1qx&spellCorrectionEnabled=true';

//   return (
//    <div className="containerfluid">
// <div class="left-column"> 
// <div className="textc">
// <p className='text'>"We facilitate faster and smoother business growth"</p> 
// <div className="subtext"><h3 style={{color:'white',paddingTop:'20px'}}>Contact Our Sales Team </h3><br /><p style={{paddingBottom:'20px'}}>Submit your information and our representative will follow up with you within 48 hours.</p></div>
// <ul className='listt'>
//    <li>
//    <CheckCircleIcon/>&nbsp; Request a Demo

//    </li>
//    <li>
//    <CheckCircleIcon/>&nbsp;Technically evaluate a solution

//    </li>
//    <li>
//    <CheckCircleIcon/>&nbsp;Request a quotation

//    </li>
//    <li>
//    <CheckCircleIcon/>&nbsp;Speak to a solutions’ expert
//    </li>
// </ul>
// </div>
//  </div>
//     <div class="container">
//       <div class="form">
//         <div class="contact-info">
//           <h3 class="title">Let's get in touch</h3>
//           <p class="text2">
//           A-1003 Gagan Arena, <br />NIBM -Corinthians Road,
//           </p>

//           <div class="info">
//             <div class="information">
             
//               <p>Undri, Pune 411060</p>
//             </div>
//             <div class="information">
              
//               <p>insecsys@insecsys.com</p>
//             </div>
//             <div class="information">
              
//               <p>+91 91686-56865| +91 83790-77907</p>
//             </div>
//           </div>

//           <div class="social-media">
//             <p>Connect with us :</p>
//             <div class="social-icons">
//               <a href="#" >
//               <InstagramIcon />
//               </a>
//               <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A10864106&keywords=insecsys&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=62bfc8c1-a6fc-4cf4-bd50-2134362db6d9&sid=1qx&spellCorrectionEnabled=true" onClick={linkedInUrl}>
//               <LinkedInIcon/>
//               </a>
//               <a href="#">
//               <WhatsAppIcon/>
//               </a>
//               <a href="#">
//                 <i class="fab fa-instagram"></i>
//               </a>
//               <a href="#">
//                 <i class="fab fa-linkedin-in"></i>
//               </a>
//             </div><br/>
         
//           </div>
//         </div>

//         <div class="contact-form">
//           <span class="circle one"></span>
//           <span class="circle two"></span>

//           <form  onSubmit={sendEmail}
//   method="POST">
//             <h3 class="title">Contact us</h3>
//             <div class="input-container">
//             <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleInputChange}
//             className="input"
//             placeholder="Your Name"
//           />
//               <label for=""></label>
//               <span>Username</span>
//             </div>
//             <div class="input-container">
//             <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             className="input"
//             placeholder="Your Email"
//           />
//               <label for=""></label>
//               <span>Email</span>
//             </div>
//             <div class="input-container">
//             <input
//             type="tel"
//             name="phone"
//             value={formData.phone}
//             onChange={handleInputChange}
//             className="input"
//             placeholder="Your Phone"
//           />
//               <label for=""></label>
//               <span>Phone</span>
//             </div>
//             <div class="input-container">
//             <select
//   name="services"
//   value={formData.services}
//   onChange={handleInputChange}
//   className="input"
//   style={{ color: 'black' }}
// >
//   <option value="" disabled selected hidden>
//     Select your services
//   </option>
  // <option value="Cyber Strategy and Governance">
  //   Cyber Strategy and Governance
  // </option>
  // <option value="Information Risk Management">
  //   Information Risk Management
  // </option>
  // <option value="Vulnerability Assessment and Penetration Testing">
  //   Vulnerability Assessment and Penetration Testing
  // </option>
  // <option value="Privacy Risk Assessments">Privacy Risk Assessments</option>
  // <option value="Product Assessments">Product Assessments</option>
  // <option value="Regulatory Assessments">Regulatory Assessments</option>
  // <option value="Data Protection Officer as a Service">
  //   Data Protection Officer as a Service
  // </option>
  // <option value="Privacy Program Development">
  //   Privacy Program Development
  // </option>
  // <option value="Staff Augmentation">Staff Augmentation</option>
  // <option value="General Data Protection Regulations (GDPR)">
  //   General Data Protection Regulations (GDPR)
  // </option>
  // <option value="HIPAA (Health Insurance Portability and Accountability Act)">
  //   HIPAA (Health Insurance Portability and Accountability Act)
  // </option>
  // <option value="India DPDP (Data Protection Bill)">
  //   India DPDP (Data Protection Bill)
  // </option>
  // <option value="Philippines Data Privacy Act (DPA)">
  //   Philippines Data Privacy Act (DPA)
  // </option>
  // <option value="Saudi Arabia Data Protection Regulations">
  //   Saudi Arabia Data Protection Regulations
  // </option>
  // <option value="UAE Data Protection Regulation">
  //   UAE Data Protection Regulation
  // </option>
  // <option value="Critical Section Control">Critical Section Control</option>
  // <option value="ISO 22301">ISO 22301</option>
  // <option value="ISO 27001">ISO 27001</option>
  // <option value="ISO 27017">ISO 27017</option>
  // <option value="ISO 27701">ISO 27701</option>
// </select>

//   <label for=""></label>
// </div>

//             <div class="input-container textarea">
//             <textarea
//             name="message"
//             value={formData.message}
//             onChange={handleInputChange}
//             className="input"
//             placeholder="Your Message"
//           ></textarea>
//               <label for=""></label>
             
//             </div>
//             <input type="submit" value="Send" class="btn" />
//           </form>
//         </div>
//       </div>
//     </div>
    
//     </div>
//   );
// }




import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../Component/Contactus.css';
import emailjs from 'emailjs-com';//install  dependancy npm install emailjs-com --force
import thankyouimg from '../Images/thankyou.png'// add image 

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    services: '',
    message: '',
  });
 // Step 1: Add state for checkbox
 const [agreementChecked, setAgreementChecked] = useState(false);
//to show alert after submitting data
  const [showAlert, setShowAlert] = useState(false);
  //submit feilds
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!agreementChecked) {
      console.error('Please agree to the terms before submitting the form.');
      return;
    }
    // from EmailJs  online website third party   all api keys
    const emailConfig = {
      service_id: 'service_dxq10it',
      template_id: 'template_1h4j137',
      user_id: 'YtuxcGwsHApB3Y5iz',
    };
    emailjs
      .sendForm(emailConfig.service_id, emailConfig.template_id, e.target, emailConfig.user_id)
      .then(
        (response) => {
          console.log('Email sent successfully:', response);
          setFormData({
            name: '',
            email: '',
            phone: '',
            services: '',
            message: '',
          });
          // Show custom styled alert
          setShowAlert(true);
          alert('Email Sent Successfully Thank You..');
          // Hide the alert after 5 seconds (adjust as needed)
          setTimeout(() => {
            setShowAlert(false);
          }, 7000);
        },
        (error) => {
          console.error('Error sending email:', error);
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Step 2: Update the state based on input type
    if (type === 'checkbox') {
      setAgreementChecked(checked);
    } else {
      setFormData({ ...formData, [name]: value });
    }}
  const handleLinkedInClick = () => {
    window.location.href = linkedInUrl;
  };

  const linkedInUrl = 'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A10864106&keywords=insecsys&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=62bfc8c1-a6fc-4cf4-bd50-2134362db6d9&sid=1qx&spellCorrectionEnabled=true';

  return (
    // main container
    <div className="containerfluid">
      {/* left column */}
      <div class="left-column">
        <div className="textc">
          <p className='text'>"We facilitate faster and smoother business growth"</p>
          <div className="subtext">
            <h3 style={{ color: 'white', paddingTop: '20px' }}>Contact Our Sales Team </h3>
            <br />
            <p style={{ paddingBottom: '20px' }}>Submit your information and our representative will follow up with you within 48 hours.</p>
          </div>
          <ul className='listt'>
            <li>
              <CheckCircleIcon />&nbsp; Request a Demo
            </li>
            <li>
              <CheckCircleIcon />&nbsp;Technically evaluate a solution
            </li>
            <li>
              <CheckCircleIcon />&nbsp;Request a quotation
            </li>
            <li>
              <CheckCircleIcon />&nbsp;Speak to a solutions’ expert
            </li>
          </ul>
        </div>
      </div>
      <div class="container">
        <div class="form">
          <div class="contact-info">
            <h3 class="title">Let's get in touch</h3>
            <p class="text2">
              A-1003 Gagan Arena, <br />NIBM -Corinthians Road,
            </p>
            <div class="info">
              <div class="information">
                <p>Undri, Pune 411060</p>
              </div>
              <div class="information">
                <p>insecsys@insecsys.com</p>
              </div>
              <div class="information">
                <p>+91 91686-56865| +91 83790-77907</p>
              </div>
            </div>
            <div class="social-media">
              <p>Connect with us :</p>
              <div class="social-icons">
                <a href="#" >
                  <InstagramIcon />
                </a>
                <a href={linkedInUrl} onClick={handleLinkedInClick}>
                  <LinkedInIcon />
                </a>
                <a href="#">
                  <WhatsAppIcon />
                </a>
                <a href="#">
                  <i class="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
              <br />
            </div>
          </div>
          <div class="contact-form">
            <span class="circle one"></span>
            <span class="circle two"></span>
          {/* form start */}
            <form onSubmit={handleSubmit} method="POST" >
              <h3 class="title">Contact us</h3>
              {/* name input feild */}
              <div class="input-container">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="input"
                  placeholder="Your Name"
                />
                <label for=""></label>
              </div>
              {/* email input feild */}
              <div class="input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="input"
                  placeholder="Your Email"
                />
                <label for=""></label>
                <span>Email</span>
                <span class="required">*</span>
              </div>
              {/* phone input feild */}
              <div class="input-container">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="input"
                  placeholder="Your Phone"
                />
                <label for=""></label>
                <span>Phone</span>
                <span class="required">*</span>
              </div>
              {/* dropdown feild */}
              <div class="input-container">
                <select
                  name="services"
                  value={formData.services}
                  onChange={handleInputChange}
                  className="input"
                  style={{ color: 'black' }}
                >
                  <option value="" disabled selected hidden>
                    Select your services
                
                  </option>
        {/* dropdown services options dropdown items */}
                  <option value="Cyber Strategy and Governance">
                        Cyber Strategy and Governance
                      </option>
                      <option value="Information Risk Management">
                        Information Risk Management
                      </option>
                      <option value="Vulnerability Assessment and Penetration Testing">
                        Vulnerability Assessment and Penetration Testing
                      </option>
                      <option value="Privacy Risk Assessments">Privacy Risk Assessments</option>
                      <option value="Product Assessments">Product Assessments</option>
                      <option value="Regulatory Assessments">Regulatory Assessments</option>
                      <option value="Data Protection Officer as a Service">
                        Data Protection Officer as a Service
                      </option>
                      <option value="Privacy Program Development">
                        Privacy Program Development
                      </option>
                      <option value="Staff Augmentation">Staff Augmentation</option>
                      <option value="General Data Protection Regulations (GDPR)">
                        General Data Protection Regulations (GDPR)
                      </option>
                      <option value="HIPAA (Health Insurance Portability and Accountability Act)">
                        HIPAA (Health Insurance Portability and Accountability Act)
                      </option>
                      <option value="India DPDP (Data Protection Bill)">
                        India DPDP (Data Protection Bill)
                      </option>
                      <option value="Philippines Data Privacy Act (DPA)">
                        Philippines Data Privacy Act (DPA)
                      </option>
                      <option value="Saudi Arabia Data Protection Regulations">
                        Saudi Arabia Data Protection Regulations
                      </option>
                      <option value="UAE Data Protection Regulation">
                        UAE Data Protection Regulation
                      </option>
                      <option value="Critical Section Control">Critical Section Control</option>
                      <option value="ISO 22301">ISO 22301</option>
                      <option value="ISO 27001">ISO 27001</option>
                      <option value="ISO 27017">ISO 27017</option>
                      <option value="ISO 27701">ISO 27701</option>
                </select>
                <label for=""></label>
              </div>
              {/* textarea input feild */}
              <div class="input-container textarea">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="input"
                  placeholder="Your Message"
                ></textarea>
                <label for=""></label>
                {/* Step 4: Add checkbox input */}
               <div className="input-container checkbox">
          <input
            type="checkbox"
            name="agreement"
            checked={agreementChecked}
            onChange={handleInputChange}
          />
            &nbsp;
            I agree to the terms and conditions. <a href="/consent">to know more</a>
            </div>
              </div>
              <input
          type="submit"
          value="Send"
          className={`btn ${!agreementChecked ? 'blur' : ''}`}
          disabled={!agreementChecked}
        />
              {showAlert && (
        <div className="custom-alert" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          {/* inside alert added image */}
          <img src={thankyouimg} alt=""  style={{height:'100%',width:'100%'}}/>
          {/* <p style={{fontSize:'1.2rem',color:'green',fontWeight:'700'}}>Thank you for getting in touch with us! Our team member will schedule a call shortly.</p> */}
        </div>
      )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
