// import { icon, logo } from '../assets';
// import { FaInstagram, FaLinkedin, FaXTwitter, FaYoutube } from 'react-icons/fa6';
// import '../Component/Footer.css';

// const Footer = () => {
//   const links = [
//     [
//       { label: 'Company', key: 'header-1' },
//       { label: 'About us', key: 'item-1-1' },
//       { label: 'blog', key: 'item-1-2' },
//       { label: 'Contact us', key: 'item-1-3' },
//       { label: 'Pricing', key: 'item-1-4' },
//       { label: 'Testimonials', key: 'item-1-5' },
//     ],
//     [
//       { label: 'Support', key: 'header-2' },
//       { label: 'Help center', key: 'item-2-1' },
//       { label: 'Terms of service', key: 'item-2-2' },
//       { label: 'Legal', key: 'item-2-3' },
//       { label: 'Privacy policy', key: 'item-2-4' },
//       { label: 'Status', key: 'item-2-5' },
//     ],
//     [
//       { label: 'Services', key: 'header-3' },
//       // Add your service links here
//       { label: 'Service 1', key: 'item-3-1' },
//       { label: 'Service 2', key: 'item-3-2' },
//       { label: 'Service 3', key: 'item-3-3' },
//       { label: 'Service 4', key: 'item-3-4' },
//     ],
//   ];

//   return (
//     <div className="footer">
//       <div className="footer-company-info">
//         <div className="footer-img">
//           <img src={icon} alt="" style={{ height: '65%', width: '20%' }} />
//           <span>PrivacyPro</span>
//         </div>

//         <div className="infos">
//           <span>Copyright © 2020 Privecy Pro ltd.</span>
//           <span>All rights reserved</span>
//         </div>
//         <div className="footer-icons">
//           <FaInstagram />
//           <FaLinkedin />
//           <FaXTwitter />
//           <FaYoutube />
//         </div>
//       </div>
//       <div className="footer-links">
//         {links.map((col, index) => (
//           <ul className={`col col-${index + 1}`} key={`col-${index}`}>
//             {col.map((link, index) => (
//               <li key={`link-${col}-${index}`}>{link.label}</li>
//             ))}
//           </ul>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Footer;

import '../Component/Footer.css';
import newlogo from '../Images/newlogo.png'
import isostamp1 from '../Images/isostamp1.png'
import isostamp2 from '../Images/isostamp2.png'
import dscitamp1 from '../Images/dsci.jpg'
import { FaInstagram, FaLinkedin, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
const Footer = () => {
    const handleButtonClick = () => {
        window.open('https://calendly.com/insecsys', '_blank');
      };
  return (
    // footer container
    <div className="footer">
        {/* footer first row */}
        <div className="footerfirstrow">
              {/* footer first row  column 1  for logo left side */}
            <div className="footerfirstrowcol1"> <img src={newlogo} alt="" className='logo1' /></div>
                  {/* footer first row  column 1  for social media icon rifht side */}
            <div className="footerfirstrowcol2">       
            <a href="https://www.instagram.com/insecsys_pvt_ltd/" target="_blank" rel="noopener noreferrer">
          <FaInstagram  className='footericon1' />
        </a>
        <a href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A10864106&keywords=InSecSys&origin=ENTITY_SEARCH_HOME_HISTORY&sid=Y!k" target="_blank" rel="noopener noreferrer">
          <FaLinkedin style={{ color: '#0077B5' }} className='footericon' />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaXTwitter style={{ color: '#1DA1F2' }} className='footericon' />
        </a>
        <a href="https://www.youtube.com/@insecsys9452" target="_blank" rel="noopener noreferrer">
          <FaYoutube style={{ color: '#FF0000' }} className='footericon' />
        </a>
        </div>
        </div>
           {/* footer first row  End*/}
        {/* footer second row */}
        <div className="footersecondrow" >
              {/* footer second row  column 1 for iso stamp and company description*/}
        <div className="footersecondrowcol1">
<img src={isostamp1} alt="" className='logoimg2' />
<img src={isostamp2} alt="" className='logoimg4' />
<img src={dscitamp1} alt="" className='logoimg3' />
            <p style={{color:'white',fontSize:'0.8rem',paddingTop:'10px'}}>Privacy pro is an Data Privacy initiative from a global cybersecurity company InSecSys private limited, having a vision to pioneer in the fields of Information Security Consulting and training globally. InSecSys has pool of seasoned Information Security Professional with experience Fortune 100 organisations, eGovernance, and industries across verticals e.g. Manufacturing, Automobile, Retail, BFSI, Defence, Telecom & IT etc.</p>
        </div>
          {/* footer second row  column 1 end */}
        {/* footer second row  column 2 comapany list */}
        <div className="footersecondrowcol2">
        <ul class="box">
          <li class="link_name">Company</li>
          <li ><a href="/whyprivacypro" style={{color:'white'}}>About Us</a></li>
          <li ><a href="/contactus" style={{color:'white'}}>Contact Us</a></li>
          <li ><a href="#" style={{color:'white'}}>Blog</a></li>
          <li ><a href="#" style={{color:'white'}}>Get Started</a></li>
        </ul>
        </div>
           {/* footer second row  column 2 end */}
           {/* footer second row  column 3 Services list */}
        <div className="footersecondrowcol3">
        <ul class="box">
        <li class="link_name">Services</li>
          <li><a href="/services" style={{color:'white'}}>Privacy Compliance Services</a></li>
          <li><a href="/services" style={{color:'white'}}>Privacy Assessments</a></li>
          <li><a href="/services" style={{color:'white'}}>Cyber Security Assessments</a></li>
          <li><a href="/services" style={{color:'white'}}>Cyber Security Services</a></li>
        </ul>
        </div>
         {/* footer second row  column 3 end */}
          {/* footer second row  column 4 supports list */}
        <div className="footersecondrowcol4">
        <ul class="box">
        <li class="link_name">Supports</li>
          <li><a href="#" style={{color:'white'}}>Help Center</a></li>
          <li><a href="#" style={{color:'white'}}>Terms of Service</a></li>
          <li><a href="#" style={{color:'white'}}>Privacy Policy</a></li>
          <li><a href="#" style={{color:'white'}}>Status</a></li>
        </ul>
        </div>
           {/* footer second row  column 4 end */}
           {/* footer second row  column 5 get in touch address and schedule button */}
        <div className="footersecondrowcol5">
        <ul class="box input-box">
         
         <li class="link_name">Get In Touch</li>
         <p className='footertitle'><i class="fas fa-map-marker-alt"></i>
           <LocationOnIcon style={{fontSize:'1.2rem',}}/>A-1003 Gagan Arena,NIBM -Corinthians Road,Undri, Pune 411060</p>
           <p className='footertitle'>
             <i class="fas fa-phone-alt"></i>
              <PhoneForwardedIcon style={{fontSize:'1.2rem',}}/> +91 91686-56865| +91 83790-77907
           </p>
           <p className='footertitle'>
             <i class="fas fa-envelope"></i>
             <MarkEmailReadIcon style={{fontSize:'1.2rem',}}/> insecsys@insecsys.com
           </p>
         <li><button className='navbarbutton' onClick={handleButtonClick}>Schedule a call</button></li>
       </ul>
        </div>
      </div>
      {/* footer second row  column 5 end */}
      {/* footer thirst row */}
      <div className="footerthirdrow">
              {/* footer thirst row  column */}
            <div className="footerthirdrowcol1"> </div>
            <div class="bottom_text">
        <span class="copyright_text">Copyright © 2023 PrivacyPro. | All rights reserved</span>
        <span class="policy_terms">
          <a>Privacy policy
          Terms & Condition</a>
        </span>
      </div>
        </div>
       
    </div>
  );
};

export default Footer;

