import React,{useEffect} from 'react';
import '../../Services/CyberSecurityServices/inforisk.css';
import '../../Services/PrivacyAssessments/productassessment.css';
import ContactForm from '../../Component/ContactForm';
import firstimg from '../../Images/risk-gamble-opportunity-swot-weakness-unsure-concept_53876-122989.jpg'
import stickerinforisk1 from '../../Images/stickerrinforisk1.png'
import infoneed from '../../Images/inforiskneed.jpg'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
export default function InformationRiskManagement() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="infocontainer">
        {/* First row start */}
      <div className="infofirstrow">
          {/* First row  colum 1 start */}
        <div className="infofirstrowcol1">
    <h1 className='infomaintitle1'>Information Risk Management</h1>
 <p style={{paddingTop:'10px',color:'white'}}>Information risk management encapsulates the array of tools, techniques, and procedures meticulously employed by an organization to mitigate vulnerabilities, hazards, and potential consequences stemming from lapses in data security. With prevalent threats such as supply chain incursions, ransomware attacks, data breaches, and denial-of-service assaults, the need for a robust approach becomes paramount.</p>
 <p style={{paddingTop:'10px',color:'white'}}> These threats often exploit weaknesses within an organization's IT infrastructure. The strategic deployment of risk management measures is imperative to safeguard against these challenges, ensuring the integrity and confidentiality of sensitive information. This professional and proactive approach is essential in the current dynamic landscape, where data security is a critical imperative for organizational resilience. </p>
        </div>
          {/* First row  colum 1 End */}
            {/* First row  colum 2 start */}
        <div className="infofirstrowcol2">
          <img src={firstimg} alt=""  className='infoimg'/>
        </div>
         {/* First row  colum 2 start */}
      </div>
     {/* First row End */}
         {/* Second row start */}
    <div className="infosecondrow">
       {/* Second row  colum 1 start */}
      <div className="infosecondrowcolumn1">
        <h1 className='cisomaintitle2'style={{marginTop:'-5vh'}}>What is Risk Management for Information?</h1>
        <p className='cisosubtitle2' style={{marginTop:'10px'}}>Information risk management in cybersecurity is a methodical process involving the identification, evaluation, and mitigation of data-related risks. The foundation is a continuous risk assessment, determining risk tolerance, monitoring potential threats, and evaluating existing controls. Risks, originating from diverse sources, are uniformly addressed by categorizing and prioritizing them based on severity. Measures are then implemented to minimize risks, such as enhancing controls and educating personnel.</p>
        <p className='cisosubtitle2'style={{marginTop:'10px'}}> The process also entails developing strategies for effective risk handling, including defined response plans. Adherence to regulatory standards is integral, ensuring compliance to safeguard the organization and prevent legal consequences.</p>
        <p className='cisosubtitle2'style={{marginTop:'10px'}}> This structured approach forms a comprehensive and professional strategy for information risk management.</p>
      </div>
       {/* Second row  colum 1 End*/}
        {/* Second row  colum 2 start */}
      <div className="infosecondrowcolumn2">
        <img src={stickerinforisk1} alt="" className='infosecondimg'/>
      </div>
       {/* Second row  colum 2 End */}
    </div>
      {/* Second row End */}
       {/* Third Row start */}
      <div className="infothirdrow">
         {/* Third row  colum 1 start */}
      <div className="infothirdrowcol1">      <img src={infoneed} alt=""  className='infothirdimg'/>
      </div>
       {/* Third row  colum 1 End */}
        {/* Third row  colum 2 start */}
        <div className="infothirdrowcol2"> <h1 className='cisomaintitle1'style={{marginTop:'-8vh'}}>What makes information risk management so important to your company?</h1>
 <p className='cisosubtitle1' style={{color:'white',marginTop:'10px'}}>Cyberattacks put businesses and organizations in every industry at significant risk. Nonetheless, cybersecurity continues to be a topic that requires careful thought as technology advances. However, a lot of people's cybersecurity preparations are weak and incomplete. Common weaknesses that increase a company's vulnerability to attack include noncompliance with industry standards, inadequate risk assessment, and reduced risk mitigation. Businesses will inevitably face the risk of unfavorable events that damage their brand and incur costly fines. Thus, establishing a strong cybersecurity risk management program is not an option but rather a need for business continuation.
</p></div>
 {/* Third row  colum 2 End */}
      </div>
       {/* Third row  End */}
    
       <div class="infofourthrowcol1">
  <h1 className='infomaintitle4'><center>Benefits  of information risk management </center> </h1>
</div>
<div class="infofourthrow">
  {/* <div class="infofourthrowcol1">
  <h1 className='infomaintitle4'>Benefits (advantages) of information risk management  </h1> */}

{/* <p className='infosubtitle6'>
  <ol>
    <li>
      <strong className='infosubtitle4'>Information risk management:</strong>Information risk management may result in cost savings with better structures, controls, and security levels.
    </li>
    <li>
      <strong className='infosubtitle4'>Business continuity:</strong>Information risk management includes the aspect of business continuity, or maintaining the company's operations in unforeseen or critical circumstances.
    </li>
    <li><strong className='infosubtitle4'>Cost-Efficient Risk Management:</strong>Early recognition of privacy threats during design minimizes post-implementation expenses, emphasizing the financial benefits of proactive assessments.
    </li>
  
  </ol>
</p> */}

  </div>
  <div class="infofourthrowcol2">
   <div className="infosubdiv1">
   <h3 className='infosubtitle5'>Understanding risk management:</h3>
<p className='infosubtitle6'>An understanding of risk management provides you with the confidence and clarity needed to make better business decisions based on real risks rather than hearsay or fear.</p>


   </div>
   <div className="infosubdiv2">

   <h3 className='infosubtitle5'>Effective information risk management:</h3>
  <p className='infosubtitle6'>Effective information risk management reduces the likelihood of a catastrophic information security incident. It will help you identify your risks and the actions required to stop a breach.</p>
   </div>
   <div className="infosubdiv3">

   <h3 className='infosubtitle5'>Strong Information Risk Management:</h3>
  <p className='infosubtitle6'>
  Effective information risk management provides the company with a competitive advantage by increasing trust, which enhances the reputation of the brand and increases sales.</p>
   </div>
   <div className="infosubdiv4">

   <h3 className='infosubtitle5'>Organizations get to prepare for cyberattacks:</h3>
  <p className='infosubtitle6'>
  Using a corporate IT network to identify and evaluate potential vulnerabilities helps organizations become ready for cyberattacks and minimize their impact, should one occur.</p>
   </div>
  </div>


<div class="cisofifthrow">
        <div className="cisofifthrowcol1"><h1 className='cisomaintitle5'>Reasons for Selecting Privacy Pro Consulting </h1></div>
        <div className="cisofifthrowcol2">
            <div class="reason-card">
                        <div class="reason-image">
                          <div className="reasonimgcol1"><PersonOutlineOutlinedIcon style={{ fontSize: 60 }}/></div>
                          <div className="reasonimgcol2">  <h3>Expert Consultants</h3></div>
                        </div>
                        <div class="reason-content">
                            <p class="reason-desc">
                          <ul>
                          <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> Our team has tons of experience making personalized privacy plans.</li>
                          <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We cover everything - from moving data across borders to managing user requests and making sure your privacy policies are top-notch.</li>
                          <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We've been around the block and know the ins and outs of privacy laws, so you're in good hands.</li>
                          <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We work closely with you to understand what you need, ensuring our plans fit your unique business.</li>
                          </ul>
                            </p>
                        </div>
            </div> 

            <div class="reason-card">
                        <div class="reason-image">
                          <div className="reasonimgcol1"><SupportAgentOutlinedIcon style={{ fontSize: 60 }}/></div>
                          <div className="reasonimgcol2">  <h3>Increase in Consumer Trust</h3></div>
                        </div>
                        <div class="reason-content">
                            <p class="reason-desc">
                            <ul>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> When you're good with privacy, people trust you more.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We make sure your customers know exactly how you're taking care of their info.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> Following good privacy rules isn't just about following the law; it's about being the kind of business people want to trust.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We help you build that trust, and that trust can give you an edge over the other guys.</li>
                            </ul>
                            </p>
                        </div>
            </div> 

            <div class="reason-card">
                        <div class="reason-image">
                          <div className="reasonimgcol1"><AdminPanelSettingsOutlinedIcon style={{ fontSize: 60 }}/></div>
                          <div className="reasonimgcol2">  <h3>Comprehensive Services</h3></div>
                        </div>
                        <div class="reason-content">
                            <p class="reason-desc">
                            <ul>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We handle everything about keeping your data safe - from the start to forever.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> Our services cover checks on your privacy health, fixing things up, and keeping a watchful eye on everything.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> You get a complete package that proves you're following all the rules and keeping things safe.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We're not just about today; we're ready for whatever privacy changes come tomorrow.</li>
                            </ul>
                            </p>
                        </div>
            </div> 

            <div class="reason-card">
                        <div class="reason-image">
                          <div className="reasonimgcol1"><PriceChangeOutlinedIcon style={{ fontSize: 60 }}/></div>
                          <div className="reasonimgcol2">  <h3>Cost Effectiveness</h3></div>
                        </div>
                        <div class="reason-content">
                            <p class="reason-desc">
                            <ul>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We believe in being smart with your money.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> Our plans are not just good; they're good for your wallet too.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> We look at where we can save you money while still doing a top-notch job.</li>
                            <li><span class="MuiIcon-root"><CheckCircleOutlinedIcon/></span> Our clear prices and clever solutions mean you get more bang for your buck.</li><br /><br />
                            </ul>
                            </p>
                        </div>
            </div> 

            
                 
                </div></div>
  {/* Contact form start */}
    <div className="cisocontact-container">
      <ContactForm />
    </div>
{/* Contact form End     */}
    </div>
  );
}