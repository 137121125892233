import React from 'react'

export default function Consent() {
  return (
   <div className="consentcontainer">
   <div className="privacypolicy">
      <center><h2 className="section-title">Privacy Policy</h2></center><br />
      <p>
        We understand that online privacy is essential for the users/visitors of our site Privacypro.
        The privacy and confidentiality of an individual’s personal information are believed to be essential.
        This Privacy Policy is provided to demonstrate PrivacyPro ’s firm commitment to privacy. By using this site, you accept the practices described in this policy.
      </p>
<br />
      <h3 className="sub-section-title">Definition of Personal Information and Sensitive Personal Information:</h3>
      <p>
        Personal Information is related to an individual and can identify individual persona like name, email id, address, phone number, IP addresses, device identifiers, location identifiers, etc.
        Some Personal Information is categorized as Sensitive Personal Information like biometric information, passwords, financial information, physical, physiological, and mental health conditions, sexual orientation, and medical records & history.
      </p>

     
      <p>
      PRIVACYPRO can modify this Privacy Policy occasionally to address new issues and reflect changes on our site. You are encouraged to review the privacy policy whenever you visit this site to ensure you understand how your personal information may be used. This policy was last modified in Dec 2023.
      </p>
<br />
      <h3 className="sub-section-title">Notice</h3>
      <p>
      PrivacyPro believes that website visitors should be given appropriate notice on how their personal information will be collected, used, or disclosed
      <br />
The privacy practices outlined in this privacy policy are for this website only. If, through this website, you are redirected to other websites or by accessing links of the other websites posted on the PrivacyPro, you are directed to other websites. Privacypro is not responsible, and Individuals should review the policies posted at those respective websites.

      </p>

    
<br />
      <h3 className="sub-section-title">Collection of Information</h3>
      <p>
      PRIVACY collects PII, like name, Job title, contact information including email address, and physical address, as well as other relevant information, both online and offline, through its various channels like (but not limited to) registration links, surveys, programs, and online-offline events. <br />
PRIVACYPRO does not collect any PII from visitors to this site who merely browse or access any content. However, if a visitor wishes to become our member and participate in our events, they might be requested to register. Registration entails filling up a form that may contain attributes of PII. <br />
When you access the PrivacyPro website from a computer, mobile phone, or other device, we may collect information from that device about your browser type, location, and IP address, as well as the pages you visit. The websites keep track of the pages a user may see by appropriately capturing the IP addresses, browser information, etc. using cookies. The data collected using cookies might
be used for analysis of user behaviour. PrivacyPro is not liable if the cookies are used by advertisers or any other third parties when you visit PrivacyPro website from other sites or if you visit another site after visiting PrivacyPro site. 
      </p>
      <br />
      <h3 className="sub-section-title">Choice and Consent</h3>
      <p>
      PrivacyPro believes that the users should be given appropriate choices, and their consent should be taken on any personal information that would be made public on the internet. PrivacyPro website provides adequate means through which individuals can make appropriate choices and consent to sharing their sensitive personal information.
      </p>
      <br />
      <h3 className="sub-section-title">Information Use</h3>
      <p>
      The information collected by PrivacyPro is used for its intended purpose and to provide specific and better services to individuals. As applicable, the information you provide to us may be used for:
      
        <ol style={{paddingLeft:'2rem'}}>
            <li>Contact you for Events, Newsletter, Workshop, Training, and other specific updates</li>
            <li>Contact you for membership status</li>
            <li>Contact you for the programs that you are associated with a website</li>
            <li>Monitor compliance with our Site privacy policy</li>
            <li>Provide unique information materials to you from us and/or our respective affiliates, subsidiaries and other third parties</li>
            <li>For aggregating information such as demographics and Site usage statistics</li>
        </ol>
      </p>
      <p>
      PrivacyPro reserves the right to share your information with organizations affiliated with PrivacyPro without your permission. PrivacyPro will share all registration details, including PII you submit while registering for our events to sponsoring vendors/partners/affiliates. Collected information will not be sold, exchanged, transferred or given to any other company for any reason whatsoever without your consent, other than the exceptions required by law, search warrants, other court orders or national exigent circumstances.
      </p>
      <br />
      <h3 className="sub-section-title">Access and Correction</h3>
      <p>PrivacyPro is a firm believer that individuals own their personal information and hence, wherever applicable, PrivacyPro provides provision update your personal information by notifying us directly via electronic-mail specified below. <br />
PrivacyPro may collect the same information multiple times from the same individuals when carrying out specific surveys or events; this collection is to update and correct individuals’ records already in possession of PrivacyPro.
</p>
<br />
<h3 className="sub-section-title">Safeguards and Disclosure</h3>
<p>
PrivacyPro shall always endeavour to safeguard the privacy of your Information and, accordingly, will not disclose the same to any third party or external organizations. Notwithstanding this, and to help you serve better, your information may be provided to our third-party vendors, advertisers, affiliates, or relevant third parties subject to your consent. Further, where such information is provided to these entities, PrivacyPro shall take all reasonable efforts but cannot guarantee to ensure that they comply with the same standards regulating the privacy of your Information as that which we impose.
</p>
<p>
However, if you wish at any time to withdraw your consent do not want your information being disclosed to these entities, or do not want to be solicited for services that we or they offer, please notify us at the contact addresses as specified below. 
</p>
<p>
While PrivacyPro at all times, endeavour to safeguard the privacy of your Information, PrivacyPro may disclose your Information to other entities where such disclosure:  

    <ol style={{paddingLeft:'2rem'}}>
       
       <li> Provided by you to attend our events, trainings, workshops</li>
       <li> is requested or authorized by you;</li>
        <li>is used for the purposes of completing, verifying or restricting any transaction made by you;</li>
        <li>is lawfully permitted or required;</li>
       <li> complies with any judicial order or legal requirement;</li>
       <li> is required to protect and defend us and our property; and
        is required to protect the interests of the public including but not limited to the detection of crimes and the apprehension of criminals.</li>
        
     
    </ol>
</p>
<p>
PrivacyPro website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that PrivacyPro has no control over other websites. Therefore, PrivacyPro cannot be responsible for the protection and privacy of any information you provide while visiting such sites, and this privacy statement does not govern such sites. You should exercise caution and look at the privacy statement applicable to the website in question.
</p>
<br />
<h3 className="sub-section-title">Privacy Contact Information</h3>
<p>
We welcome your questions and comments about our website's privacy policy. Comments or complaints can be directed to PrivacyPro by email at <a href="mailto:contact@privacypro.in">contact@privacypro.in</a>.  
</p>
<p>
PrivacyPro will do its best to resolve any complaints as quickly as possible.  
</p>
    </div>
   </div>
  )
}
