
import React, { useEffect, useRef } from 'react';
import '../PrivacyRegulations/saudi.css'
import gdprimg from '../../Images/gdprimg.jpg'
import whatisgdpr from '../../Images/what is gdpr.jpg'
import needgdpr from '../../Images/needgdpr-removebg-preview.png'
import ContactForm from '../../Component/ContactForm';
import pdpa from '../../Images/pdpa.png'
export default function SaudiArabiaDPA() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const animationRef = useRef(null);
  useEffect(() => {
    const currentRef = animationRef.current;
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animationRef.current.classList.add('start-animation');
        } else {
          animationRef.current.classList.remove('start-animation');
        }
      });
    };
    const options = {
        threshold: 0.30,
      };
    const observer = new IntersectionObserver(callback, options);

    if (animationRef.current) {
      observer.observe(animationRef.current);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [animationRef]);
  return (
    <div className='saudicontainer'>
    <div className="saudifirstrow">
                <div className="saudifirstrowcol">
                  <h1><center className='hippamaintitle'>Saudi Arabia Data Privacy Act (Saudi Arabia DPA)</center></h1>
                  <p className='phsubtitle1'>Saudi Arabia's new Personal Data Protection Law, featuring 43 articles, establishes a robust framework for safeguarding individual privacy in domestic and international data processing. The legislation grants individuals rights, mandates strict data correction and destruction procedures, and imposes penalties for non-compliance. Overseen by a dedicated Competent Authority, the law emphasizes organizational, administrative, and technical measures, addressing specific data categories like Health and Credit Data, with clear supervisory mechanisms and penalties for violations set to take effect after a preparatory period.</p>
                </div>
                  <div className="saudifirstrowcol2"> <img src={pdpa} alt="" className='hippaimg'/></div>
    </div>
    <div className="saudithirdrow">
                <div className="saudithirdrowcol1">
                  <h3 >
                    <center className='hippamaintitle'style={{marginTop:'-5vh'}}>What is Saudi Arabia  Data Privacy Act?</center>
                  </h3>
                  <p style={{ color: 'white' }}>
                  Saudi Arabia's Data Privacy Act comprises 43 articles, ensuring a stringent framework for protecting personal information domestically and internationally. The law grants individuals rights, mandates corrective measures, and imposes penalties for non-compliance, overseen by a dedicated Competent Authority. The legislation addresses specific data categories and is set to take effect after a preparatory period, reflecting Saudi Arabia's commitment to robust data protection.
                  </p>
                </div>
                <div className="saudithirdrowcol2">
                  <img src={whatisgdpr} alt="" className='whatissaudi' />
                </div>
    </div>

    <div className="saudifourthrow">
                  <div className="saudifourthrowcol1">
                    <img src={needgdpr} alt="" className='saudineed'/>
                  </div>
                  <div className="saudifourthrowcol2">
                    <h3>
                      <center style={{ paddingTop: '20px', fontSize: '30px',marginTop:'-9vh' }}>Why Is Saudi Arabia DPA Compliance Necessary for Your Organization?</center>
                    </h3>
                    <p style={{ color: 'black' }}>
                    <ul>
                          <li>
                            <strong>Legal Requirement:</strong> Familiarity with the Saudi Arabia Data Protection Act is crucial for legal compliance, ensuring that your business adheres to the country's regulations on personal data handling.
                          </li>
                          <li>
                            <strong>oiding Penalties: </strong> Non-compliance may result in legal penalties, fines, or other sanctions, emphasizing the need to understand and adhere to the specific provisions outlined in the Act.
                          </li>
                          <li>
                            <strong>Enhanced Trust and Reputation:</strong> Adhering to data protection laws enhances your business's reputation, signalling a commitment to ethical practices and safeguarding customer trust.
                          </li>
                          <li>
                            <strong>Global Business Requirements:</strong> : If your business operates globally or deals with Saudi Arabian customers, compliance with the local data protection regulations becomes imperative.
                          </li>
                        </ul>
                    </p>
                  </div>
    </div>
    <div className="saudififthrow">
                    <div className="saudififthrowcol">
                      <div class="gdpranimation2" ref={animationRef}>
                        <div className="brnifitcontent"><center className='phbenifitcontent'>Benefits of<br /> Data Privacy Act<br /> Compliance</center></div>
                        <div class="saudibox saudibox1"><center> <h3 className='gdpranimationText'>Conduct In-depth Assessment </h3> </center> <p className='phsubtitle3'>Our DPA experts conduct a comprehensive compliance evaluation, identifying weaknesses and offering strategic recommendations to enhance your data protection strategy. We're committed to assisting your organization in achieving robust and sustained DPA compliance aligned with regulatory standards and best practices.
                        </p></div>
                        <div class="gdprsaudislide-tr"><center> <h3 className='gdpranimationText'>Develop Customized Strategy:</h3> </center> <p className='phsubtitle3'>We design a DPA compliance plan based on your particular needs because we recognize that every organization is different. Our staff will collaborate with you to pinpoint problem areas and create a thorough compliance plan.</p></div>
                        <div class="gdprsaudislide-right"><center><h3 className='gdpranimationText'>Offer Training Programs: </h3> </center> <p className='phsubtitle3'> We give training courses that help your staff members comprehend the criteria for DPA compliance. Our programs are customized for your company and help guarantee that your employees have the skills necessary to stay in compliance.</p></div>
                        <div class="gdprsaudislide-left"><center><h3 className='gdpranimationText'>Monitor Compliance: </h3> </center> <p className='phsubtitle3'>We provide ongoing oversight of your DPA compliance strategy to guarantee that all regulations are being followed. Any non-compliance will be pointed up by our team, along with suggestions for correction. Frequent monitoring supports DPA compliance by lowering the possibility of expensive data breaches.</p></div>
                        <div class="gdprsaudislide-tl"><center><h3 className='gdpranimationText'>Provide Implementation Support: </h3> </center> <p className='phsubtitle3'>Our staff offers practical assistance and direction during the whole implementation process. We assist you in optimizing your workflow and guarantee that your DPA compliance strategy is carried out successfully.</p></div>
                      </div>
                    </div>
    </div>
    <div className="contactform">
      <ContactForm />
    </div>
  </div>
  );
}

