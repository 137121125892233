import React from 'react';
import './landingpage.css'; 
import anim2 from '../Images/bgvideo3.mp4'
import { useNavigate } from 'react-router-dom';
import ContactForm from './ContactForm';
import PauseOnHover from './Carousel';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import DevicesIcon from '@mui/icons-material/Devices';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import PolicyIcon from '@mui/icons-material/Policy';
import image from '../Images/dd.jpg'
import OurPartners from './OurPartner';
export default function LandingPage() {
  const navigate = useNavigate();

  const redirectTo = (path) => {
    navigate(path);
  };

  const iconStyle = {
    fontSize: '60px', 
    color: 'linear-gradient(to right, pink, red, blue)', 
    WebkitBackgroundClip: 'text', 
    display: 'inline-block', 
  };
  return (
    // main container start
    <div className='landingcontainer'>
              {/* first row start */}
              <div className="row1">
                {/* first row column 1  start*/}
                      <div className="row1column1">
                        {/* video tag for animation video add */}
                              <video autoPlay loop muted playsInline className="background-video" >
                              <source src={anim2} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                              <h1 className="tagline">Privacy Protected, Proven by Privacy Pro</h1>
                      </div>
                        {/* first row column 1  end*/}
              </div>
                {/* first row end */}
                {/* carousel container start */}
              <div class="carousl">
                {/* carousel have 3 sub div for divide screen  */}
                    <div class="carodiv1"></div>
                    <div class="carodiv2">
                    <PauseOnHover/>
                    </div>
                    <div class="carodiv3"></div>
              </div>
                {/* carousel container end */}
                {/* contact form  container start */}
                <div className="contactform"> 
                <ContactForm/>
                </div>
              {/* contact form  container end */}        
              {/* service  container start */}       
               <div className="servicesectioncontainer">
                {/* services container row 1 start */}
                <div className="servicesectioncontainerrow1">
                   {/* services container row 1  column 1 start */}
                  <div className="servicesectioncontainerrow1col1">
                          <h3 className='homeservicemaintitle'>          
                          <center style={{color:'white'}}>Privacy Pro Consulting is dedicated to going above and beyond mere regulatory adherence.</center>
                          </h3>
                                  <p className='homeservicesubtitle'>
                                    As an endeavor of Privacy Pioneers & Experts, we deliver comprehensive solutions to address all your privacy and digital security needs.

                                    In today's tech-driven world, safeguarding the confidentiality and digital safety of your users has never been more vital. This is where our seasoned and devoted team excels. Collaborate with Privacy Pro to ensure your business consistently leads the way in data confidentiality advisory and cybersecurity advisory services.
                            
                                    We'll assist you in devising strategies, rectifying vulnerabilities, creating, executing, and sustaining robust confidentiality and cybersecurity measures using our Data Privacy Services that conform to all sector-specific compliance standards.
                            
                                    Don't settle for mere compliance—differentiate yourself by elevating privacy to your Unique Selling Proposition (USP). Here are the offerings we provide:
                                  </p>
                  </div>
                  {/* services container row 1  column 1 end */}
                  {/* services container row 1  column 2 start */}
                  <div className="servicesectioncontainerrow1col2">
                    <img src={image} alt="" className='homeimage1'/>
                  </div>
                  {/* services container row 1  column 2 end */}
                </div>
                {/* service container row2 start */}
                <div className="servicesectioncontainerrow2">
                   {/* service container row2 card 1 start */}
                <div class="homeservice-card">
                  {/* service container row2 card 1 image upper body of card */}
                <div class="homeservice-image">
                   {/* service container row2 card 1 image upper body of card  icon section*/}
                <div className="homeserviceimagecol1">
                <VpnKeyOffIcon style={iconStyle}/>
                </div>
                {/* service container row2 card 1 image upper body of card  icon section end*/}
                {/* service container row2 card 1 image upper body of card  title section*/}
                  <div className="homeserviceimagecol2">
                    <h1 className='homeservicetitle1'> Privacy Compliance Services</h1>
                  </div>
                  {/* service container row2 card 1 image upper body of card  title section end*/}
                </div>
                <div class="homeservice-content">
                  {/* service container row2 card 1 image lower body of card  description section*/}
          <button className='homepagebutton' onClick={() => redirectTo('/services/dpo')}>DPO Service</button>
          <button className='homepagebutton' onClick={() => redirectTo('/services/staffaugumentation')}>Staff Augmentation</button>
          <button className='homepagebutton' onClick={() => redirectTo('/services/privacyprogram')}>Privacy Program Development</button>
                </div>
                </div>
                {/* service container row2 card 1 end*/}
                {/* service container row2 card 2 start*/}
                <div class="homeservice-card">
                  {/* service container row2 card 2 image upper body of card */}
                  <div class="homeservice-image">
                   {/* service container row2 card 2 image upper body of card  icon section*/}
                <div className="homeserviceimagecol1">
                <VpnLockIcon style={iconStyle}/>
                </div>
                  <div className="homeserviceimagecol2">
                    <h1 className='homeservicetitle1'> Privacy Assessments</h1>
                  </div>
                </div>
                <div class="homeservice-content">
                <button className='homepagebutton' onClick={() => redirectTo('/services/productassessment')}>Product Assessment</button>
      <button className='homepagebutton' onClick={() => redirectTo('/services/privacyriskassessment')}>Regulatory Assessment</button>
      <button className='homepagebutton' onClick={() => redirectTo('/services/regulatoryassessment')}>Privacy Risk Assessment</button>
                </div>
                </div>
                {/* service container row2 card 2 end*/}
                {/* service container row2 card 3 start*/}
                <div class="homeservice-card">
                  {/* service container row2 card 3 image upper body of card */}
                  <div class="homeservice-image">
                   {/* service container row2 card 3 image upper body of card  icon section*/}
                <div className="homeserviceimagecol1">
                <DevicesIcon style={iconStyle}/>
                </div>
                  <div className="homeserviceimagecol2">
                    <h1 className='homeservicetitle1'> Cyber Security Assessments</h1>
                  </div>
                </div>
                <div class="homeservice-content">
                <button className='homepagebutton' onClick={() => redirectTo('/services/tprm')}>TPRM Assessment</button>
      <button className='homepagebutton' onClick={() => redirectTo('/services/csma')}>CSM Assessment</button>
                </div>
                </div>
                {/* service container row2 card 3 end*/}
                {/* service container row2 card 4 start*/}
                <div class="homeservice-card">
                  {/* service container row2 card 4 image upper body of card */}
                  <div class="homeservice-image">
                   {/* service container row2 card 4 image upper body of card  icon section*/}
                <div className="homeserviceimagecol1">
                <VpnKeyOffIcon style={iconStyle}/>
                </div>
                  <div className="homeserviceimagecol2">
                    <h1 className='homeservicetitle1'> Cyber Security Service</h1>
                  </div>
                </div>
                <div class="homeservice-content">
      <button  className='homepagebutton' onClick={() => redirectTo('/services/ciso')}>CISO as a service</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/vapt')}>VAPT</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/inforisk')}>Information Risk Management</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/csag')}>Cyber strategy and Governance</button>
                </div>
                </div>
                 {/* service container row2 card 4 end*/}
                {/* service container row2 card 5 start*/}
                <div class="homeservice-card">
                  {/* service container row2 card 5 image upper body of card */}
                  <div class="homeservice-image">
                   {/* service container row2 card 5 image upper body of card  icon section*/}
                <div className="homeserviceimagecol1">
                <GppMaybeIcon style={iconStyle}/>
                </div>
                  <div className="homeserviceimagecol2">
                    <h1 className='homeservicetitle1'> Privacy Regulations</h1>
                  </div>
                </div>
                <div class="homeservice-content">
      <button  className='homepagebutton' onClick={() => redirectTo('/services/gdpr')} >GDPR </button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/dpdp')}>India DPDP</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/philippines')}>HIPAA</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/uaedpa')}>UAE DPA</button>
                </div>
                </div>
                {/* service container row2 card 5 end*/}
                {/* service container row2 card 6 start*/}
                <div class="homeservice-card">
                  {/* service container row2 card 6 image upper body of card */}
                  <div class="homeservice-image">
                   {/* service container row2 card 6 image upper body of card  icon section*/}
                <div className="homeserviceimagecol1">
                <PolicyIcon style={iconStyle}/>
                </div>
                  <div className="homeserviceimagecol2">
                    <h1 className='homeservicetitle1'> Security and Privacy Standards</h1>
                  </div>
                </div>
                <div class="homeservice-content">
                <button  className='homepagebutton' onClick={() => redirectTo('/services/iso27001')}>ISO 27001</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/iso27701')}>ISO 27701</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/iso22301')}>ISO 22301</button>
      <button  className='homepagebutton' onClick={() => redirectTo('/services/iso27017')}>ISO 27017</button>
             
                </div>
                </div>
                 {/* service container row2 card 6 end*/}
                </div>
                {/* service container row2 end */}
          </div>
          {/* our partners page start  */}
      <div className="ourparte">
        <OurPartners/>
      </div>
      {/* our partners page end  */}
    </div>
  );
}
