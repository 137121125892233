import React,{useEffect} from 'react';
import '../Resources/blogs.css'
import blogimg1 from '../Images/OpSec.png'
import blogimg from '../Images/blogimg1.jpg'
import blogimg2 from '../Images/blogimg2.jpg'
import blogimg3 from '../Images/belogimg3.jpg'
export default function Blogs() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (

      <div className='blogcontainer'>
        {/* first row */}
      <div class='blogfirstrow'>
        {/* first row column1 */}
        <div class="blogfirstrowcol1">

        <h1 className='blogmainheading'>Blogs</h1>
        
        <p style={{paddingTop:'10px'}} className="blogsubcontaint">Navigate the digital landscape confidently with our curated insights on privacy and cybersecurity. Elevate your awareness, safeguard your online presence — because your security is our priority.</p>
        
        </div>
         {/* first row column 2 */}
        <div className="blogfirstrowcol2">
       <img src={blogimg} alt=" " className='blogimage1'  />
        </div>
        </div>
{/* End of first row */}
<div class="blogsecondrowcontainer">
   <div class="blogsecondrow">

    <div class="blog-card">
        <div class="blog-image"><img src={blogimg1} alt="" className='blogimg'/></div>
         <div class="blog-content">
            <a href="#">
                <span class="blog-title">
                    INTRODUCTION: Operational Technology Security
                </span>
            </a>

            <p class="blog-desc">
                <p className='blogtitle'>The procedure and tools used to prevent physical operational systems from malfunctioning as a result of unintentional or malicious damage are known as OT security (operational technology security). The systems in the industrial (such as manufacturing) and infrastructure (such as electricity, water, and gas) domains are especially pertinent to OT security.</p>
            </p>

            <a class="blog-action" href="https://www.linkedin.com/feed/update/urn:li:activity:7013485219127169024" target="_blank" rel="noopener noreferrer">
               More Info 
               <span aria-hidden="true">
               →
               </span>
            </a>
         </div>
    </div>
    <div class="blog-card">
  <div class="blog-image">
    <img src={blogimg2} alt="" className='blogimg'/>
  </div>
  <div class="blog-content">
    <a href="#">
      <span class="blog-title">
        INTRODUCTION: Phishing and Social Engineering Tactics
      </span>
    </a>

    <p class="blog-desc">
      <p className='blogtitle'>One of the most popular attack strategies used by threat actors to target organisations and people is phishing. The goal of this attack strategy is to persuade a victim to click on a harmful link or open a malicious attachment. It heavily focuses on social engineering. (The objective is frequently to persuade users to divulge their login information or other private information.)</p>
    </p>

    <a class="blog-action" href="https://www.linkedin.com/posts/insecsys_business-training-cybersecurity-activity-6976518000099094528-aIdL?utm_source=share&utm_medium=member_android" target="_blank" rel="noopener noreferrer">
      More Info 
      <span aria-hidden="true">
        →
      </span>
    </a>
  </div>
</div>

    <div class="blog-card">
        <div class="blog-image"><img src={blogimg3} alt="" className='blogimg'/></div>
         <div class="blog-content">
            <a href="#">
                <span class="blog-title">
                    INTRODUCTION: ISO 27001 Compliance Checklist
                </span>
            </a>

            <p class="blog-desc">
                <p className='blogtitle'>A member of the ISO/IEC 27000 family of standards, ISO 27001. As it relates to informatie security management, this certification is a key component of the 150 27000 system. Each Usted. It focuses on information security and provides opportunities for management of cars avers by companies ISO 27001 outlines the standards for an Information Security Management System (15M5S) using a risk-based approach.</p>
            </p>

            <a class="blog-action" href="https://www.linkedin.com/posts/insecsys_certification-checklist-for-iso-27001-activity-7006323476554768384-PZPp?utm_source=share&utm_medium=member_android" target="_blank" rel="noopener noreferrer">
               More Info 
               <span aria-hidden="true">
               →
               </span>
            </a>
         </div>
    </div>
   </div>
    </div>
    </div>
  );
}