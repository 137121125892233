import React,{useEffect} from 'react'
import '../Component/webinar.css'
import webinar from '../Images/webinar.png'
import webinarimg1 from '../Images/webinarimg1.jpeg'
import webinarimg2 from '../Images/webinarimg2.jpeg'
import webinarimg3 from '../Images/webinarimage3.png'
export default function Webinars() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <div class="webinarcontainer">
    <div class="wcrow1">
        
    </div>
    <div class="wcrow2">
        
                   

                <div class="webinar-card">
                <div class="webinar-image"><img src={webinarimg1} alt="" className='webimg'/></div>
                <div class="webinar-content">
                    <a href="#">
                    <span class="webinar-title">
                        How to start Complience Journey.
                    </span>
                    </a>

                    <p class="webinar-desc">
                    <b className='namee'>Mr. Shitanshu Kumar</b><br />
                    CISO and DPO at InSecSys Private Limited.
                    </p>

                    <a class="webinar-action" href="https://www.youtube.com/watch?v=VslMASZ8PTo" target="_blank" rel="noopener noreferrer">
                   Watch Now
                    <span aria-hidden="true">
                        →
                    </span>
                    </a>
                </div>
                </div>

                <div class="webinar-card">
                <div class="webinar-image"><img src={webinarimg2} alt="" className='webimg2'/></div>
                <div class="webinar-content">
                    <a href="#">
                    <span class="webinar-title">
                        Navigating ISO 27001 for robust Infromation Security
                    </span>
                    </a>

                    <p class="webinar-desc">
                    <b className='namee'>Mr. Sanjeev Kumar</b><br />
                    Head Information Security-IT
                    </p>

                    <a class="webinar-action" href="https://www.youtube.com/watch?v=ZJzKAjC02hY" target="_blank" rel="noopener noreferrer">
                    Watch Now
                    <span aria-hidden="true">
                        →
                    </span>
                    </a>
                </div>
                </div>
                <div class="webinar-card">
                <div class="webinar-image"><img src={webinarimg3} alt="" className='webimg2'/></div>
                <div class="webinar-content">
                    <a href="#">
                    <span class="webinar-title">
                        Protecting Our Clients Digital Assets With Cutting Edge Technology And Advance Threat Intelligence.
                    </span>
                    </a>

                    <p class="webinar-desc">
                    <b className='namee'>Mr. Indranil Chatterjee</b><br />
                  Cyber Security Expert.
                    </p>

                    <a class="webinar-action" href="https://www.youtube.com/watch?v=t_iXG9bAv9w&t=3s" target="_blank" rel="noopener noreferrer">
                    Watch Now
                    <span aria-hidden="true">
                        →
                    </span>
                    </a>
                </div>
                </div>

            

    </div>
  </div>
  
  )
}
