

import React from 'react'
import '../Component/ourpartner.css';
import instasafe from '../../src/Images/instasafe2.jpg'
import everen from '../../src/Images/evren.png'
import webonoise from '../../src/Images/sophos.jpeg'
import cobot from '../../src/Images/cobotpng.png'
import IBM from '../../src/Images/qradar.png'
import Kaspersky from '../../src/Images/Kaspersky-Logo.jpg'
import Logsign from '../../src/Images/logsign_logo-900x300.png'
import crowdstrike from '../../src/Images/crowdstrike.png'
import balanco1 from '../../src/Images/balanco.png'
import innowave from '../../src/Images/innowave.jpg'
import balanco2 from '../../src/Images/balancoce.jpg'
import tuvsud from '../../src/Images/tuvsud.png'
import paessler from '../../src/Images/paessler.png'
import ironscales from '../../src/Images/ironscales.png'
import code24 from '../../src/Images/code42.jpg'
import vicarius from '../../src/Images/vicarius.png'
import radiflow from '../../src/Images/radiflow.png'
import axonius from '../../src/Images/axonius.png'
import motadata from '../../src/Images/motadata.jpg'
import gurucul from '../../src/Images/gurucul2.jpg'
import kratikal from '../../src/Images/kratikal.png'
import itsmine from '../../src/Images/itsmine.png'
import timedoctor from '../../src/Images/timedoctor.png'
import sechard from '../../src/Images/sechard.png'
import indusface from '../../src/Images/indusface.png'
import seceon from '../../src/Images/seceon.png'
import aithent from '../../src/Images/aithent.png'
import bugcrowd from '../../src/Images/bugcrowd.jpg'
import sentinelone from '../../src/Images/sentrinelone.png'

import MoreInfo from './Moreinfo';
export default function OurPartner() {
  return (
    <div className="newourpartnercontainer">
      <div className="newourpartnercontainerfirstrow">
        <div className="newourpartnercontainerfirstrowcol1">
          <div className="dummy"><h1><center>Our Partners</center></h1>
          <br />
          <p>"Collaborating with industry leaders and innovators, our esteemed partners contribute to our shared success, bringing expertise and synergy to every endeavor."</p>
          <hr /></div>
        
      
        </div>
        <div className="newourpartnercontainerfirstrowcol2">
<img src={instasafe} alt=""  className='ourpartnerimage'/>
<img src={everen} alt="" className='ourpartnerimage' />
<img src={webonoise} alt=""  className='ourpartnerimage'/>
<img src={cobot} alt=""  className='ourpartnerimage'/>
<img src={IBM} alt=""  className='ourpartnerimage'/>
<img src={Kaspersky} alt=""  className='ourpartnerimage'/>
<img src={Logsign} alt=""  className='ourpartnerimage'/>


<img src={crowdstrike} alt=""  className='ourpartnerimage'/>
<img src={balanco1} alt="" className='ourpartnerimage' />
<img src={innowave} alt=""  className='ourpartnerimage'/>
<img src={balanco2} alt=""  className='ourpartnerimage'/>
<img src={tuvsud} alt=""  className='ourpartnerimage'/>
<img src={paessler} alt=""  className='ourpartnerimage'/>
<img src={ironscales} alt=""  className='ourpartnerimage'/>



<img src={code24} alt=""  className='ourpartnerimage'/>
<img src={vicarius} alt="" className='ourpartnerimage' />
<img src={radiflow} alt=""  className='ourpartnerimage'/>
<img src={axonius} alt=""  className='ourpartnerimage'/>
<img src={motadata} alt=""  className='ourpartnerimage'/>
<img src={gurucul} alt=""  className='ourpartnerimage'/>
<img src={kratikal} alt=""  className='ourpartnerimage'/>


<img src={itsmine} alt=""  className='ourpartnerimage'/>
<img src={timedoctor} alt="" className='ourpartnerimage' />
<img src={sechard} alt=""  className='ourpartnerimage'/>
<img src={indusface} alt=""  className='ourpartnerimage'/>
<img src={seceon} alt=""  className='ourpartnerimage'/>
<img src={aithent} alt=""  className='ourpartnerimage'/>
<img src={bugcrowd} alt=""  className='ourpartnerimage'/>

<img src={sentinelone} alt=""  className='ourpartnerimage'/>


        </div>
      </div>
      <div>
        <MoreInfo/>
      </div>
    </div>
  )
}

