
import React, { useEffect, useRef } from 'react';
import '../PrivacyRegulations/uaedpa.css'
import gdprimg from '../../Images/gdprimg.jpg'
import whatisgdpr from '../../Images/what is gdpr.jpg'
import needgdpr from '../../Images/needgdpr-removebg-preview.png'
import ContactForm from '../../Component/ContactForm';
import pdpa from '../../Images/pdpa.png'
export default function UAEDPA() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const animationRef = useRef(null);
  useEffect(() => {
    const currentRef = animationRef.current;
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animationRef.current.classList.add('start-animation');
        } else {
          animationRef.current.classList.remove('start-animation');
        }
      });
    };
    const options = {
        threshold: 0.30,
      };
    const observer = new IntersectionObserver(callback, options);

    if (animationRef.current) {
      observer.observe(animationRef.current);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [animationRef]);
  return (
    <div className='uaecontainer'>
    <div className="uaefirstrow">
                <div className="uaefirstrowcol">
                  <h1><center className='hippamaintitle'>UAE Data Privacy Act (UAE DPA)</center></h1>
                  <p className='phsubtitle1'>The UAE Data Privacy Act (UAEDPA) represents a pivotal milestone in the country's legal landscape, notably the Federal Decree-Law No. 45 of 2021. Enacted on January 2, 2022, this comprehensive legislation signifies a departure from the UAE's previous fragmented approach to data protection. The UAEDPA is designed to uphold the privacy rights of data subjects, with a focus on regulating the acquisition and processing of personal data. Its provisions echo global standards and necessitate a profound understanding by organizations to ensure compliance and safeguard against data breaches.</p>
                </div>
                  <div className="uaefirstrowcol2"> <img src={pdpa} alt="" className='hippaimg'/></div>
    </div>
    <div className="uaethirdrow">
                <div className="uaethirdrowcol1">
                  <h3 >
                    <center className='hippamaintitle'style={{marginTop:'-5vh'}}>What is UAE  Data Privacy Act?</center>
                  </h3>
                  <p style={{ color: 'white' }}>
                  The UAE Data Privacy Act (UAEDPA), officially known as the Federal Decree-Law No. 45 of 2021, is a groundbreaking legislation effective since January 2, 2022. It serves as the first comprehensive federal law in the UAE dedicated to protecting the privacy of data subjects. The UAEDPA regulates the acquisition and processing of personal data, providing a unified framework to replace the prior fragmented legislation. Its focus on global standards underscores its significance for organizations in ensuring compliance and safeguarding individuals' privacy rights.
                  </p>
                </div>
                <div className="uaethirdrowcol2">
                  <img src={whatisgdpr} alt="" className='whatisuae' />
                </div>
    </div>

    <div className="uaefourthrow">
                  <div className="uaefourthrowcol1">
                    <img src={needgdpr} alt="" className='uaeneed'/>
                  </div>
                  <div className="uaefourthrowcol2">
                    <h3>
                      <center style={{ paddingTop: '20px', fontSize: '30px',marginTop:'-10vh' }}>Why Is UAE DPA Compliance Necessary for Your Organization?</center>
                    </h3>
                    <p style={{ color: 'black' }}>
                    <ul>
                          <li>
                            <strong>Legal Requirement:</strong> Compliance with the UAE Data Privacy Act (UAEDPA) is a legal necessity, as failure to adhere to its provisions can lead to legal consequences and penalties.
                          </li>
                          <li>
                            <strong>Protection of Individual Rights:</strong> UAEDPA ensures the protection of individual rights by regulating the acquisition and processing of personal data, fostering trust between organizations and data subjects.
                          </li>
                          <li>
                            <strong>Enhanced Trust and Reputation:</strong> Demonstrating compliance with UAEDPA enhances trust among customers and stakeholders, contributing to a positive reputation for your organization as one that respects and protects privacy.
                          </li>
                          <li>
                            <strong>Global Business Requirements:</strong>                             <strong>Global Business Requirements:</strong>As a part of global data protection standards, UAEDPA compliance aligns organizations with international best practices, facilitating smoother engagement in global business environments with evolving privacy regulations.

                          </li>
                        </ul>
                    </p>
                  </div>
    </div>
    <div className="uaefifthrow">
                    <div className="uaefifthrowcol">
                      <div class="gdpranimation2" ref={animationRef}>
                        <div className="brnifitcontent"><center className='phbenifitcontent'>Benefits of<br /> Data Privacy Act<br /> Compliance</center></div>
                        <div class="uaebox uaebox1"><center> <h3 className='gdpranimationText'>Conduct In-depth Assessment </h3> </center> <p className='phsubtitle3'>Our DPA experts conduct a comprehensive compliance evaluation, identifying weaknesses and offering strategic recommendations to enhance your data protection strategy. We're committed to assisting your organization in achieving robust and sustained DPA compliance aligned with regulatory standards and best practices.
                        </p></div>
                        <div class="gdpruaeslide-tr"><center> <h3 className='gdpranimationText'>Develop Customized Strategy:</h3> </center> <p className='phsubtitle3'>We design a DPA compliance plan based on your particular needs because we recognize that every organization is different. Our staff will collaborate with you to pinpoint problem areas and create a thorough compliance plan.</p></div>
                        <div class="gdpruaeslide-right"><center><h3 className='gdpranimationText'>Offer Training Programs: </h3> </center> <p className='phsubtitle3'> We give training courses that help your staff members comprehend the criteria for DPA compliance. Our programs are customized for your company and help guarantee that your employees have the skills necessary to stay in compliance.</p></div>
                        <div class="gdpruaeslide-left"><center><h3 className='gdpranimationText'>Monitor Compliance: </h3> </center> <p className='phsubtitle3'>We provide ongoing oversight of your DPA compliance strategy to guarantee that all regulations are being followed. Any non-compliance will be pointed up by our team, along with suggestions for correction. Frequent monitoring supports DPA compliance by lowering the possibility of expensive data breaches.</p></div>
                        <div class="gdpruaeslide-tl"><center><h3 className='gdpranimationText'>Provide Implementation Support: </h3> </center> <p className='phsubtitle3'>Our staff offers practical assistance and direction during the whole implementation process. We assist you in optimizing your workflow and guarantee that your DPA compliance strategy is carried out successfully.</p></div>
                      </div>
                    </div>
    </div>
    <div className="contactform">
      <ContactForm />
    </div>
  </div>
  );
}

